@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'Gellix';
    src: url(/static/media/Gellix-LightItalic.4a958be8.eot);
    src: local('Gellix Light Italic'), local('Gellix-LightItalic'),
        url(/static/media/Gellix-LightItalic.4a958be8.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gellix-LightItalic.ecf9f5a4.woff2) format('woff2'),
        url(/static/media/Gellix-LightItalic.3c8af4de.woff) format('woff'),
        url(/static/media/Gellix-LightItalic.902e9237.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gellix';
    src: url(/static/media/Gellix-ExtraBold.0b2ca0f4.eot);
    src: local('Gellix ExtraBold'), local('Gellix-ExtraBold'),
        url(/static/media/Gellix-ExtraBold.0b2ca0f4.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gellix-ExtraBold.441e5f0b.woff2) format('woff2'),
        url(/static/media/Gellix-ExtraBold.413780e8.woff) format('woff'),
        url(/static/media/Gellix-ExtraBold.565a95dd.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gellix';
    src: url(/static/media/Gellix-SemiBold.4ffbab93.eot);
    src: local('Gellix SemiBold'), local('Gellix-SemiBold'),
        url(/static/media/Gellix-SemiBold.4ffbab93.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gellix-SemiBold.c5cec639.woff2) format('woff2'),
        url(/static/media/Gellix-SemiBold.26ae79f7.woff) format('woff'),
        url(/static/media/Gellix-SemiBold.e2d3608e.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gellix';
    src: url(/static/media/Gellix-RegularItalic.fe91a551.eot);
    src: local('Gellix Regular Italic'), local('Gellix-RegularItalic'),
        url(/static/media/Gellix-RegularItalic.fe91a551.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gellix-RegularItalic.912fc163.woff2) format('woff2'),
        url(/static/media/Gellix-RegularItalic.9f422106.woff) format('woff'),
        url(/static/media/Gellix-RegularItalic.38a89468.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gellix';
    src: url(/static/media/Gellix-ThinItalic.215b76b6.eot);
    src: local('Gellix Thin Italic'), local('Gellix-ThinItalic'),
        url(/static/media/Gellix-ThinItalic.215b76b6.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gellix-ThinItalic.83c0a5cf.woff2) format('woff2'),
        url(/static/media/Gellix-ThinItalic.f53bb7bf.woff) format('woff'),
        url(/static/media/Gellix-ThinItalic.6f468283.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Gellix';
    src: url(/static/media/Gellix-Bold.124072cd.eot);
    src: local('Gellix Bold'), local('Gellix-Bold'),
        url(/static/media/Gellix-Bold.124072cd.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gellix-Bold.0058174c.woff2) format('woff2'),
        url(/static/media/Gellix-Bold.0079f40c.woff) format('woff'),
        url(/static/media/Gellix-Bold.fc0cdc3c.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gellix';
    src: url(/static/media/Gellix-ExtraBoldItalic.65fa10d8.eot);
    src: local('Gellix ExtraBold Italic'), local('Gellix-ExtraBoldItalic'),
        url(/static/media/Gellix-ExtraBoldItalic.65fa10d8.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gellix-ExtraBoldItalic.23a13566.woff2) format('woff2'),
        url(/static/media/Gellix-ExtraBoldItalic.f5ec4604.woff) format('woff'),
        url(/static/media/Gellix-ExtraBoldItalic.bdbd6f74.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Gellix';
    src: url(/static/media/Gellix-Regular.8c441671.eot);
    src: local('Gellix Regular'), local('Gellix-Regular'),
        url(/static/media/Gellix-Regular.8c441671.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gellix-Regular.8e7d54ec.woff2) format('woff2'),
        url(/static/media/Gellix-Regular.47dd9b4f.woff) format('woff'),
        url(/static/media/Gellix-Regular.356e0bc8.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gellix';
    src: url(/static/media/Gellix-Medium.60d1b4ef.eot);
    src: local('Gellix Medium'), local('Gellix-Medium'),
        url(/static/media/Gellix-Medium.60d1b4ef.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gellix-Medium.bba92fb3.woff2) format('woff2'),
        url(/static/media/Gellix-Medium.3bd5d005.woff) format('woff'),
        url(/static/media/Gellix-Medium.48afad41.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gellix';
    src: url(/static/media/Gellix-SemiBoldItalic.e84d1802.eot);
    src: local('Gellix SemiBold Italic'), local('Gellix-SemiBoldItalic'),
        url(/static/media/Gellix-SemiBoldItalic.e84d1802.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gellix-SemiBoldItalic.fb26e70a.woff2) format('woff2'),
        url(/static/media/Gellix-SemiBoldItalic.8138c5e2.woff) format('woff'),
        url(/static/media/Gellix-SemiBoldItalic.7e2115d8.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Gellix';
    src: url(/static/media/Gellix-Light.1f3ae9fe.eot);
    src: local('Gellix Light'), local('Gellix-Light'),
        url(/static/media/Gellix-Light.1f3ae9fe.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gellix-Light.423c8401.woff2) format('woff2'),
        url(/static/media/Gellix-Light.0c2e3649.woff) format('woff'),
        url(/static/media/Gellix-Light.429dd803.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gellix';
    src: url(/static/media/Gellix-BoldItalic.28cf6b58.eot);
    src: local('Gellix Bold Italic'), local('Gellix-BoldItalic'),
        url(/static/media/Gellix-BoldItalic.28cf6b58.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gellix-BoldItalic.74c06d88.woff2) format('woff2'),
        url(/static/media/Gellix-BoldItalic.b80d4133.woff) format('woff'),
        url(/static/media/Gellix-BoldItalic.8c1e70df.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gellix';
    src: url(/static/media/Gellix-BlackItalic.106fcc67.eot);
    src: local('Gellix Black Italic'), local('Gellix-BlackItalic'),
        url(/static/media/Gellix-BlackItalic.106fcc67.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gellix-BlackItalic.1bd5cf09.woff2) format('woff2'),
        url(/static/media/Gellix-BlackItalic.790f2409.woff) format('woff'),
        url(/static/media/Gellix-BlackItalic.939a53f3.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gellix';
    src: url(/static/media/Gellix-Thin.c5921b9a.eot);
    src: local('Gellix Thin'), local('Gellix-Thin'),
        url(/static/media/Gellix-Thin.c5921b9a.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gellix-Thin.989adb14.woff2) format('woff2'),
        url(/static/media/Gellix-Thin.65220114.woff) format('woff'),
        url(/static/media/Gellix-Thin.fc578a2c.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gellix';
    src: url(/static/media/Gellix-Black.9795164c.eot);
    src: local('Gellix Black'), local('Gellix-Black'),
        url(/static/media/Gellix-Black.9795164c.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gellix-Black.9268c734.woff2) format('woff2'),
        url(/static/media/Gellix-Black.34a02909.woff) format('woff'),
        url(/static/media/Gellix-Black.54f857c6.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gellix';
    src: url(/static/media/Gellix-MediumItalic.e49fb4e0.eot);
    src: local('Gellix Medium Italic'), local('Gellix-MediumItalic'),
        url(/static/media/Gellix-MediumItalic.e49fb4e0.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gellix-MediumItalic.2868ff58.woff2) format('woff2'),
        url(/static/media/Gellix-MediumItalic.dff6a9fe.woff) format('woff'),
        url(/static/media/Gellix-MediumItalic.7c5c865b.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

